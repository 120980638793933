<template>
  <div>
    <t-logo-upload
      :value="value"
      :disabled="readonly"
      accept="image/png,image/jpeg,image/jpg"
      @input="onInput"
    >
      <img v-if="fallback" :src="fallback" class="t-logo-upload__logo">
      <dashboard-image v-else name="upload" />
    </t-logo-upload>
  </div>
</template>
<script>
import DashboardImage from '~/components/DashboardImage.vue';

export default {
  components: { DashboardImage },
  props: {
    value: {
      type: [Object, Function, window.File, String, Blob],
      required: false,
      default: null,
    },

    fallback: {
      type: String,
      required: false,
      default: null,
    },

    readonly: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onInput(value) {
      this.$emit('input', value);
      this.$emit('change', value);
    },
  },
};
</script>

<style>
.t-logo-upload__image,
.t-logo-upload__unset {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
</style>
