<template>
  <TLoading v-if="$fetchState.pending" />
  <t-page v-else>
    <template #header>
      <h3>
        {{ creating ? $t('pages.profile.actions.create') : $t('pages.profile.actions.edit') }}
      </h3>
    </template>

    <t-form-card>
      <div class="profile-form">
        <validation-observer ref="validator" v-slot="{ invalid, handleSubmit }" tag="div">
          <t-form @submit.native.prevent="handleSubmit(save)">
            <t-grid>
              <t-grid-row>
                <t-grid-cell :size="2">
                  <t-validation-wrapper
                    vid="logo"
                    :name="$t('fields.profile_logo')"
                    :rules="getLogoRules()"
                  >
                    <OrganizationProfileLogoHelp slot="help" />
                    <TLogo v-model="form.logo" :fallback="form.logo_url" />
                  </t-validation-wrapper>
                </t-grid-cell>

                <t-grid-cell :size="10">
                  <t-validation-wrapper
                    vid="name"
                    :name="$t('fields.profile_name')"
                    rules="required|min:3|max:255"
                  >
                    <OrganizationProfileNameHelp slot="help" />
                    <t-input
                      v-model="form.name"
                      native-type="text"
                      :placeholder="$t('fields.organization_name_placeholder')"
                    />
                  </t-validation-wrapper>
                </t-grid-cell>
              </t-grid-row>

              <t-grid-row>
                <t-grid-cell>
                  <t-validation-wrapper
                    vid="meta.description"
                    :name="$t('fields.description')"
                    rules="required|min:30|max:1000"
                  >
                    <OrganizationProfileDescriptionHelp slot="help" />
                    <t-textarea v-model="form.meta.description" rows="8" />
                  </t-validation-wrapper>
                </t-grid-cell>
              </t-grid-row>

              <t-grid-row>
                <t-grid-cell>
                  <t-validation-wrapper
                    vid="meta.color"
                    :name="$t('fields.profile_color')"
                    rules="string|color"
                  >
                    <OrganizationProfileColorHelp slot="help" />
                    <t-input v-model="form.meta.color" native-type="text">
                      <span
                        slot="left"
                        style="display: inline-block; border-radius: 4px; width: 15px; height: 15px"
                        :style="{ 'background-color': form.meta.color }"
                      />
                    </t-input>
                  </t-validation-wrapper>
                </t-grid-cell>
              </t-grid-row>

              <t-grid-row>
                <t-grid-cell>
                  <t-validation-wrapper
                    vid="meta.website_url"
                    :name="$t('fields.website')"
                    rules="url"
                  >
                    <OrganizationProfileWebsiteUrlHelp slot="help" />
                    <t-input
                      v-model="form.meta.website_url"
                      native-type="text"
                      :placeholder="$t('fields.e_g', { term: 'https://stafftastic.com' })"
                    />
                  </t-validation-wrapper>
                </t-grid-cell>
              </t-grid-row>

              <t-grid-row>
                <t-grid-cell>
                  <t-validation-wrapper
                    vid="meta.statistics.employee_count"
                    :name="$t('fields.employee_count')"
                    rules="required|integer|min:1"
                  >
                    <t-input
                      v-model="form.meta.statistics.employee_count"
                      min="0"
                      native-type="number"
                    />
                  </t-validation-wrapper>
                </t-grid-cell>
                <t-grid-cell>
                  <t-validation-wrapper
                    vid="meta.statistics.trainee_count"
                    :name="$t('fields.trainee_count')"
                    rules="required|integer|min:0"
                  >
                    <t-input
                      v-model="form.meta.statistics.trainee_count"
                      min="0"
                      native-type="number"
                    />
                  </t-validation-wrapper>
                </t-grid-cell>
                <t-grid-cell>
                  <t-validation-wrapper
                    vid="meta.statistics.training_job_count"
                    :name="$t('fields.training_job_count')"
                    rules="required|integer|min:0"
                  >
                    <t-input
                      v-model="form.meta.statistics.training_job_count"
                      min_value="0"
                      native-type="number"
                    />
                  </t-validation-wrapper>
                </t-grid-cell>
              </t-grid-row>

              <t-grid-row>
                <div
                  style="
                    flex-direction: row-reverse;
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    align-items: center;
                  "
                >
                  <t-button-loading :disabled="invalid" :loading="loading" native-type="submit">
                    {{ $t('global.actions.save') }}
                  </t-button-loading>

                  <a v-if="!creating" href="." @click.prevent="deleteConfirmationVisible = true">{{
                    $t('global.actions.delete')
                  }}</a>
                </div>
              </t-grid-row>
            </t-grid>
          </t-form>
        </validation-observer>
      </div>
    </t-form-card>

    <portal to="modals">
      <t-confirm
        v-if="deleteConfirmationVisible"
        :button-label="$t('global.actions.confirm_deletion')"
        @confirmed="destroy"
        @cancelled="deleteConfirmationVisible = false"
      >
        <template #header>
          {{ $t('global.confirmation.delete') }}
        </template>
        <p>{{ $t('global.confirmation.final_action') }}</p>
      </t-confirm>
    </portal>
  </t-page>
</template>

<script>
import Vue from 'vue';
import Profile from '~/models/Profile';
import TLoading from '~/components/TLoading';
import TLogo from '~/components/TLogo';
import TFormCard from '~/components/TFormCard';
import TValidationWrapper from '~/components/TValidationWrapper';
import OrganizationProfileLogoHelp from '~/components/Help/OrganizationProfileLogoHelp';
import OrganizationProfileNameHelp from '~/components/Help/OrganizationProfileNameHelp';
import OrganizationProfileDescriptionHelp from '~/components/Help/OrganizationProfileDescriptionHelp';
import OrganizationProfileColorHelp from '~/components/Help/OrganizationProfileColorHelp';
import OrganizationProfileWebsiteUrlHelp from '~/components/Help/OrganizationProfileWebsiteUrlHelp';
import UserTypeEnum from '~/enums/UserTypeEnum';

export default Vue.extend({

  components: {
    TLoading,
    TLogo,
    TFormCard,
    TValidationWrapper,
    OrganizationProfileLogoHelp,
    OrganizationProfileNameHelp,
    OrganizationProfileDescriptionHelp,
    OrganizationProfileColorHelp,
    OrganizationProfileWebsiteUrlHelp,
  },

  props: {
    organizationId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      profile: null,
      form: null,
      loading: false,

      deleteConfirmationVisible: false,
    };
  },

  async fetch() {
    if (this.$route.params?.profile_id) {
      this.profile = await Profile.$find(this.$route.params.profile_id);
    }

    this.form = this.getForm(this.profile);
  },

  computed: {
    creating() {
      return !(this.profile && this.profile.id);
    },
  },

  methods: {
    getForm(profile = null) {
      return {
        name: profile?.name,
        logo: null,
        logo_url: profile?.logo_url,
        meta: {
          description: profile?.meta?.description,
          color: profile?.meta?.color,
          website_url: profile?.meta?.website_url,
          statistics: {
            employee_count: profile?.meta?.statistics?.employee_count,
            trainee_count: profile?.meta?.statistics?.trainee_count,
            training_job_count: profile?.meta?.statistics?.training_job_count,
          },
        },

        organization_id: this.organizationId,
      };
    },

    getPayload() {
      return {
        ...this.form,
      };
    },

    getLogoRules() {
      return this.creating
        ? 'required|mimes:image/png,image/jpeg,images/jpg|size:2048'
        : 'mimes:image/png,image/jpeg,images/jpg|size:2048';
    },

    rerouteToIndex() {
      const destinationRoute = [UserTypeEnum.ADMIN, UserTypeEnum.TENANT_MEMBER].includes(
        this.$auth.me.user.type,
      )
        ? { name: this.$RouteEnum.ORGANIZATION.PROFILES, params: { id: this.organizationId } }
        : { name: this.$RouteEnum.PROFILES.INDEX };
      return this.$router.push(destinationRoute);
    },

    async save() {
      if (this.creating) {
        return await this.create(this.getPayload());
      }

      return await this.update(this.getPayload());
    },

    async create(payload) {
      this.loading = true;
      try {
        await new Profile({
          ...payload,
        }).save();

        await this.$notify.success(this.$t('notifications.profile.created'));
        await this.rerouteToIndex();
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async update(payload) {
      this.loading = true;
      try {
        await new Profile({
          id: this.profile.id,
          ...payload,
        }).patch();

        await this.$notify.success(this.$t('notifications.profile.updated'));
      } catch (e) {
        this.$axios.handleError(e, this.$refs.validator);
      } finally {
        this.loading = false;
      }
    },

    async destroy() {
      try {
        await new Profile({
          id: this.profile.id,
        }).delete();

        await this.$notify.success(this.$t('notifications.profile.deleted'));
        await this.rerouteToIndex();

        this.deleteConfirmationVisible = false;
      } catch (e) {
        this.$axios.handleError(e);
      }
    },
  },
});
</script>
