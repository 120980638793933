<script>
import THelp from '~/components/THelp';

export default {
  extends: THelp,

  props: {
    label: {
      type: String,
      required: false,
      default:
        'Laden Sie eine JPG oder PNG Datei Ihres Logos hoch. Das Logo wird später in dem Bewerber:in-Anschreiben eingebaut.',
    },
  },
};
</script>
